.footer {
  background-color: black;
  color: #fff;
  padding: 20px;
  text-align: center;
  z-index: 1;
}

.footer-columns {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  margin-bottom: 20px;
}

.footer-column {
  flex: 1;
  margin: 0 20px;
  text-align: start;
}

.footer-column h3 {
  font-size: 1.2rem;
  margin-bottom: 10px;
  font-weight: bolder;
}

.footer-column ul {
  list-style: none;
  padding: 0;
}

.footer-column ul li {
  margin-bottom: 5px;
}

.footer-column ul li a {
  text-decoration: none;
  color: inherit;
}

.footer-line {
  margin-top: 20px;
  text-align: end;
  font-weight: bolder;
}
