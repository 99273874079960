.content-left {
  font-size: 6vh; /* Adjust the font size as needed */
  font-weight: bolder;
  text-align: start;
  margin: 8vh 0;
  padding-left: 16px;
}

.content-right {
  font-size: 6vh; /* Adjust the font size as needed */
  font-weight: bolder;
  text-align: end;
  margin: 8vh 0;
  padding-right: 16px;
}

.center-text {
  font-size: 10vh; /* Adjust the font size as needed */
  font-weight: bolder;
  text-align: center;
  margin: 8vh 4px;
}

.motion-span {
  display: inline-block;
  margin-left: 4px;
}

@media screen and (max-width: 576px) {
  .motion-span {
    display: block;
  }
}
