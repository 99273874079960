.common {
  padding: 12vh 2vw 0 2vw;
}

.common h1,
.common p {
  font-weight: bolder;
}

.common p,
.common li {
  font-size: large;
  font-weight: bold;
}

.common caption {
  font-weight: bold;
  display: flex;
  color: black;
}
