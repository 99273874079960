.home {
  animation: transitionIn 1s;
}

@keyframes transitionIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}
