.contact-us {
  padding-top: 12vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 24px;
}

.contact-us h1 {
  font-weight: bolder;
}
