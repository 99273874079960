.navbar {
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0));
  backdrop-filter: blur(1px); /* Adjust the blur radius as needed */
  z-index: 3;
  position: fixed;
  width: 100vw;
}

.brand-logo:hover {
  filter: invert(1);
}

.navbar-nav .nav-link {
  font-size: 3.2vh; /* Adjust the font size as needed */
  font-weight: bolder;
  cursor: pointer;
  margin-right: 0 1.6vh;
}

.navbar-nav .nav-link:hover {
  text-decoration: underline;
}

.stay-safe {
  text-decoration: underline;
}
