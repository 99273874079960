.point {
  font-size: 24vh;
  font-weight: bolder;
  text-align: center;
  margin: -16vh 0 -12vh 0;
}

.why-point {
  display: flex;
  padding: 4vh 0 8vh 0;
}

@media screen and (max-width: 1300px) {
  /* Adjust breakpoint as needed */
  .why-point {
    flex-direction: column; /* Change to column layout on small screens */
  }
}

.to-me-container {
  padding: 20px;
  justify-self: center;
  align-self: center;
  text-align: left;
  display: flex;
  flex-direction: column;
  width: 100vw;
}

.from-me-container {
  padding: 20px;
  justify-self: center;
  align-self: center;
  text-align: right;
  display: flex;
  flex-direction: column;
  width: 100vw;
}

.to-me-container p,
.from-me-container p {
  font-size: 3vh;
  line-height: 1.4;
  margin: 1px 0;
  padding: 8px 17px 6px 13px;
  max-width: 100vw;
  position: relative;
  border-radius: 18px;
}

.to-me-container p:after,
.from-me-container p:after {
  position: absolute;
  content: "";
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
}

.to-me-container p.to-me {
  color: black;
  align-self: flex-start;
  background-color: #ede7f6;
}

.from-me-container p.from-me {
  color: white;
  align-self: flex-end;
  background-color: #673ab7;
}

.to-me-container p.to-me:after {
  background: url("../../components/home/to_me_bubble.svg") left bottom
    no-repeat;
  left: -6px;
}

.from-me-container p.from-me:after {
  background: url("../../components/home/from_me_bubble.svg") right bottom
    no-repeat;
  right: -6px;
}

.download-logo {
  display: flex;
  justify-content: center;
  gap: 4vw;
  margin: 5vh 2vw 6vh 2vw;
}

@media screen and (max-width: 576px) {
  /* Adjust breakpoint as needed */
  .download-logo {
    flex-direction: column; /* Change to column layout on small screens */
    align-items: center;
  }
}
